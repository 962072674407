@import '../../../../styles/variables.scss';
@import '../../../../styles/mixins.scss';
@import '../../../../styles/themes.scss';

.gambit {
  @include sofiaRegular();
  font-size: 20px;
  line-height: 30px;
  color: $black;
  min-height: 120px;
  white-space: pre-wrap;

  &.left {
    text-align: left;
  }

  &.default {
    @include media-breakpoint-up(md) {
      font-size: 24px;
      line-height: 36px;
    }
  }

  &.small {
    font-size: 14.4px;
    line-height: 21.6px;
    min-height: 0px;
    
    @include media-breakpoint-up(md) {
      font-size: 16px;
      line-height: 24px;
    }

    .dropcap {
      font-size: 65px;
      line-height: 75px;

      @include media-breakpoint-up(md) {
        font-size: 75px;
        line-height: 85px;
      }
    }
  }

  &.darkTheme {
    color: $white-floral;
  }

  .hidden {
    display: none;
  }

  .dropcap {
    font-size: 85px;
    font-family: $harrybeastdisplay;
    line-height: 95px;
    padding-right: 10px;
    margin-top: -20px;
    float: left;
    color: $lightMagic;
    position: relative;
    top: 20px;
    text-transform: uppercase;

    @include media-breakpoint-up(md) {
      font-size: 120px;
      line-height: 120px;
    }

    @supports (-webkit-background-clip: text) or (background-clip: text) {
      color: transparent;

      &.default {
        background-image: linear-gradient(
            -45deg,
            transparent 0% 48%,
            rgba(#fff, 0.8) 50%,
            transparent 52% 100%
          ),
          linear-gradient(45deg, $lightMagic, $lightMagic);
      }
      &.red {
        background-image: linear-gradient(26deg, #81302e, #943330);
      }
      &.green {
        background-image: linear-gradient(169deg, #a4c3bc, #2f6a5d);
      }
      &.blue {
        background-image: linear-gradient(37deg, #19375d, #1d3e6e 100%);
      }
      &.yellow {
        background-image: linear-gradient(40deg, #664816, #e79a20 100%);
      }

      background-clip: text;
      background-size: 500% 500%, 100% 100%;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      animation: Gradient 3s ease infinite;
    }
  }

  &.outcomeTheme {
    color: $white-floral;

    .dropcap {
      font-family: $harrybeastdisplay;
    }
  }
}

[data-themed='true'] .gambit {
  color: var($--theme-text-color);

  .dropcap {
    background-color: var($--theme-drop-cap-color);
    background-image: var(
      $--theme-drop-cap-bg-image,
      linear-gradient(-45deg, transparent 0% 48%, rgba(#fff, 0.8) 50%, transparent 52% 100%),
      linear-gradient(45deg, $lightMagic, $lightMagic)
    );
  }
}

@keyframes Gradient {
  0% {
    background-position: 0% 0%;
  }
  100% {
    background-position: 100% 100%;
  }
}
